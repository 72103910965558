.carousel .slide img {
  border: 2px solid black;
  width: 100%;
}

.carousel .thumbs-wrapper {
  background-color: #000; /* Black background for thumbnails */
}

.carousel .thumb {
  border: 2px solid #fff; /* White border for thumbnails */
}

.carousel .control-arrow {
  background: none; /* Remove default background */
  border: none; /* Remove default border */
  color: black; /* Black color for arrows */
  font-size: 10px; /* Increase arrow size */
  cursor: pointer;
}
